import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PublicHomeModule } from './modules/public-home/public-home.module';
import { SharedService } from './modules/shared/services/shared.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppChatbotComponent } from './components/app-chatbot/app-chatbot.component'
import { FormsModule } from '@angular/forms';
import { DirectiveModule } from './modules/shared/directives/directive.module';
import { SharedModule } from './modules/shared/shared/shared.module';
import { ContentListModule } from './modules/contentList/content-list.module';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { MdoUserListModule } from './modules/mdoUserList/mdo-user-list.module';
import { InitService } from './modules/shared/services/init.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { ToastrModule } from 'ngx-toastr';
import { PipePublicURLModule } from './pipe-public-URL/pipe-public-URL.module';
import { CommoncomponentsModule } from './modules/shared/shared/commoncomponents/commoncomponents.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
const appInitializer = (initSvc: InitService) => async () => {
  try {
    await initSvc.init()
  } catch (error) {
    console.error('ERROR DURING APP INITIALIZATION >', error)
  }
}

@NgModule({
  declarations: [
    AppComponent,
    AppChatbotComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    SharedModule,
    CommoncomponentsModule,
    PublicHomeModule,
    // PostalModule,
    // RailwayModule,
    DirectiveModule,
    ContentListModule,
    MdoUserListModule,
    PaginationModule.forRoot(),
    TranslateModule.forRoot({
      loader: {  
        provide: TranslateLoader,  
        useFactory: HttpLoaderFactory,  
        deps: [HttpClient]  
      }  
    }),
    ToastrModule.forRoot(),
    PipePublicURLModule,
    NgbModalModule
  ],
  providers: [
    {
      deps: [InitService],
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
    },
    SharedService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {  
  return new MultiTranslateHttpLoader(http, [  
    { prefix: "./assets/i18n/", suffix: ".json" },  
  ]);  
}  
