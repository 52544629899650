import { Component, HostListener, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { MultilingualTranslationService } from '../../services/multilingual-translation.service';
import { LANGUAGES } from '../../constant/app.constant';
import { NavigationEnd, Router } from '@angular/router';
import { EventService } from '../../services/event.service';
import { WsEvents } from '../../services/events';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  currentLanguage: string = 'en';
  @Input() navBarData: any;
  currentIndex: number = 0;
  languages = LANGUAGES;
  isScrolled = false;
  isTab = false;
  @ViewChild('contentRegistration', {static: true}) contentRegistrationRef: TemplateRef<any>;
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition > 50) {
      this.isScrolled = true;

    } else {
      this.isScrolled = false;
    }

    let newsroomOffSet;
    const scrollPositionWindow = window.scrollY + 100;
    const newsroom = document.getElementById('newsroom');
    if (newsroom) {
      newsroomOffSet = document.getElementById('newsroom').offsetTop - 100;
    }
    if (scrollPositionWindow > newsroomOffSet && scrollPositionWindow < newsroomOffSet + 200) {
      this.currentIndex = 3;
    } 
    else if(this.currentIndex ==3){
      this.currentIndex =0
    }
    else {
      this.currentIndex = this.currentIndex? this.currentIndex :0
    }

  }

  constructor(
    private sharedSvc: SharedService,
    public translate: TranslateService,
    private miltilingualService: MultilingualTranslationService,
    private router: Router,
    private eventSvc: EventService,
    private modalService: NgbModal) {
      const lang = localStorage.getItem('lang');
      if (lang) this.currentLanguage = lang;
      else this.currentLanguage = 'en';
  }

  ngOnInit() {
    if (window.innerWidth >= 768 && window.innerWidth <=991) {
      this.isTab = true
    } else {
      this.isTab = false
    }

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
          if (event.url.includes('/career')) {
            this.currentIndex = 4
          } else if (event.url.includes('/contactus')) {
            this.currentIndex = 5
          } 
          else if (event.url.includes('/aboutus')) {
            this.currentIndex = 1
          } 
 
      }
  })
  }

  getLinks(url: string) {
    return this.sharedSvc.baseUrl + url
  }

  redirectToExternalLink(url, i: number) {
    if (url['link'] && url['fragment'] === '') {
      window.open(url['link'], '_blank')
    } else {
      this.currentIndex = i;
    }
  }

  languageChange(event) {
    this.currentLanguage = event.target.value;
    this.raiseTemeletyInterat(event.target.value)
    this.translate.use(this.currentLanguage);
    localStorage.setItem('lang', this.currentLanguage);
    this.miltilingualService.setlanguageChange(this.currentLanguage);
  }

  routeNavigation(path: any, fragment: string,i:number) {
    if(path.link == 'career'){
      this.router.navigate(['/career'], { fragment: fragment })
      this.currentIndex =i
    }
    else if(path.link === 'tenders'){
      this.router.navigate(['/tenders'])
      this.currentIndex =i
    }
    else if(path.link == 'contactus'){
     this.router.navigate(['/contactus'],{fragment:fragment})
     this.currentIndex =i
    }
    else if(path.link == 'aboutUs'){
      this.router.navigate(['/aboutus'])
      this.currentIndex =i
    }
    else {
      this.router.navigate(['/'], { fragment: fragment })
    }
    
  }

  raiseTemeletyInterat(id: string) {
    console.log("id ", id)
    const event: any = {
      eventType: WsEvents.WsEventType.Telemetry,
      eventLogLevel: WsEvents.WsEventLogLevel.Info,
      data: {
        edata: { type: 'click', id: id, subType: 'language-toggle'},
        state: WsEvents.EnumTelemetrySubType.Interact,
        eventSubType: WsEvents.EnumTelemetrySubType.cardContent,
        mode: 'view'
      },
      pageContext: {pageId: '/', module: 'Landing Page'},
      from: '',
      to: 'Telemetry',
    }
    this.eventSvc.dispatchChatbotEvent<WsEvents.IWsEventTelemetryInteract>(event)
  }

  openRegistrationInfoDialog() {
    this.modalService.open(this.contentRegistrationRef, { size: 'lg', centered: true });
  }
}
